<template>
  <v-container ma-1 pa-1 fluid id="main-edit">
    <v-layout align-center justify-center v-if="loadingFeedback">
      <a-spin class="mt-2" tip="Loading..." />
    </v-layout>
    <div v-else>
      <v-toolbar flat color="white">
        <!-- {{userInfo}} -->
        <!-- <v-toolbar-title> Feedbacks </v-toolbar-title> -->
        <a-input
          addon-before="Search"
          v-model="search"
          placeholder="Search feedback"
          append-icon="search"
          outlined
          style="width: 30%"
          class="mr-2"
        />
        <v-divider class="mx-4" inset vertical></v-divider>

        <span id="box-border">依頼内容:</span>

        <a-select style="width: 20%" class="mr-2" v-model="filterRequestType">
          <a-select-option
            v-for="item in optRequestDetails"
            :key="item"
            v-value="item"
            >{{ item }}</a-select-option
          >
        </a-select>
        <span id="box-border">Reply Status:</span>
        <a-select style="width: 10%" class="mr-1" v-model="filterReplyStatus">
          <a-select-option
            v-for="item in optReplyStatus"
            :key="item"
            v-value="item"
            >{{ item }}</a-select-option
          >
        </a-select>

        <v-divider class="mx-4" inset vertical></v-divider>

        <!-- <v-checkbox
            v-if="isAdmin()"
            class="mt-5 mr-3"
            v-model="filterIsImportant"
            label="Important Reply"
        ></v-checkbox> -->

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <!--   @click="
                feedbackDialog = true;
                item = {feedBackNo:fn};
                isEdit = false;
                images = [];
                attachments = [];
              " -->
            <a-button
              type="primary"
              v-on="on"
              icon="question-circle"
              @click="addFeedback()"
            >
              New</a-button
            >
          </template>
          <span>Add Feedback</span>
        </v-tooltip>
      </v-toolbar>

      <v-card flat class="pa-2 ma-2" v-if="feedBackData.length > 0">
        <v-row class="mb-10">
          <!-- <pre> {{filterFeedback}}</pre> -->

          <h1 v-if="filterFeedback.length == 0">No Record(s) Found!</h1>
          <!-- 2022-02-21 -->

          <!-- <v-col cols="4" v-for="item in filterFeedback" :key="item.comment"> -->
          <v-col cols="4" v-for="item in paginatedFeedback" :key="item.comment">
            <a-card style="width: 380">
              <a-divider orientation="left">
                <b class="mr-2">Feedback No.</b>{{ item.feedBackNo }}
              </a-divider>

              <template slot="actions" class="ant-card-actions">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="EditFeedback(item)"
                      v-if="item.feedbackBy == userInfo.name"
                      >mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit Feedback</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      v-if="isAdmin()"
                      @click="openDiscussion(item)"
                      >mdi-forum</v-icon
                    >
                  </template>
                  <span>Reply Feedback</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      v-if="item.feedbackBy == userInfo.name || isAdmin()"
                      @click="deleteFeedback(item)"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>Delete Feedback</span>
                </v-tooltip>
              </template>
              <p>
                <span id="cardDetails">{{ item.requestType }}</span>
              </p>
              <p>
                <span id="cardDetails">{{ item.requestDetails }}</span>
              </p>
              <p>
                Attachments:
                <span v-if="item.attachments.length > 0">
                  <template v-for="(file, i) in item.attachments">
                    <a-tag
                      style="cursor: pointer"
                      :key="i"
                      color="blue"
                      @click="FileSrc(file)"
                    >
                      {{ file }}
                    </a-tag>
                  </template>
                </span>
                <span v-else>N/A</span>
              </p>

              <p>
                お客様コード :
                <span v-if="item.consCode">
                  {{ item.consCode }}
                </span>
                <span v-else>N/A</span>
              </p>
              <p v-if="item.documentType == 'SHIYO' && item.requestType == '仕様マニュアルページの不具合' ">
                <p>
                <!-- 2022-01-17 || item.requestType == 'その他' -->
                仕様書番号 :
                <span v-if="item.shiyoNumber && item.requestType == '仕様マニュアルページの不具合'">
                  
                  <a-tag
                    style="cursor: pointer"
                    color="green"
                    @click="openShiyoDocument(item.docNo, item.productName)"
                  >
                    {{ item.shiyoNumber }}
                  </a-tag>
                </span>
                <span v-else>N/A</span>
              </p>
              <p v-if="item.documentType == 'SekkeiGyoumu'">
                <!-- 2022-01-17 -->
                設計業務マニュ番号 :
                <span v-if="item.docNo">
                  <a-tag
                    style="cursor: pointer"
                    color="green"
                    @click="openSekkeiDocument(item.docNo)"
                  >
                    {{ item.docNo }}
                  </a-tag>
                </span>
                <span v-else>N/A</span>
              </p>
              <p>
                <!-- 2022-01-17 -->
                ルールブック番号 :
                <span v-if="item.docNo && item.requestType == 'ルールブックページの不具合'">
                  <a-tag
                    style="cursor: pointer"
                    color="green"
                    @click="openDocument(item.docNo)"
                  >
                    {{ item.docNo }}
                  </a-tag>
                </span>
                <span v-else>N/A</span>
              </p>
              <p>
                <span
                  >Feedback By: {{ item.feedbackBy }} ({{
                    item.feedbackDate
                  }})</span
                >
              </p>
              <p>
                <v-btn
                  color="primary"
                  dense
                  small
                  @click="item.isPreview = !item.isPreview"
                  :disabled="
                    item.attachments.toString().match(/.jpg/g) ||
                    item.attachments.toString().match(/.png/g)
                      ? false
                      : true
                  "
                >
                  Image Preview
                  <v-icon v-if="!item.isPreview">mdi-eye</v-icon>
                  <v-icon v-else>mdi-eye-off</v-icon>
                </v-btn>
              </p>
              <template v-for="(record, r) in item.src">
                <v-layout row wrap :key="r">
                  <span v-if="item.isPreview">{{ item.attachments[r] }}</span>
                  <img v-if="item.isPreview" :src="record" width="350" />
                </v-layout>
              </template>
              <a-divider orientation="left">Reply Details</a-divider>
              <p>
                Reply:
                <!-- ongoing -->
                <span v-if="item.replyDetails">
                  {{ item.replyDetails.reply }} ({{ item.replyDetails.date }})

                  <v-icon
                    v-if="item.replyDetails.replyIsImportant == true"
                    color="red"
                  >
                    mdi-flag
                  </v-icon>
                  <!-- 2022-02-21 -->
                </span>

                <span v-else>N/A</span>
              </p>

              <p>
                Attachments:
                <template v-if="item.replyDetails">
                  <template v-if="item.replyDetails.replyAttachments">
                    <template
                      v-for="(file, i) in item.replyDetails.replyAttachments"
                    >
                      <a-tag
                        style="cursor: pointer"
                        :key="i"
                        color="blue"
                        @click="FileSrc(file)"
                      >
                        {{ file }}
                      </a-tag>
                    </template>
                  </template>
                  <template v-else>
                    <span>N/A</span>
                  </template>
                </template>
                <template v-else>
                  <span>N/A</span>
                </template>
                <!-- <template v-if="!item.replyDetails.replyAttachments">
                    <span>N/A</span>
                  </template>
                  <template v-else>
                    <template v-for="(file, i) in item.replyDetails.replyAttachments">
                      <a-tag
                        style="cursor: pointer"
                        :key="i"
                        color="blue"
                        @click="FileSrc(file)"
                      >
                        {{ file }}
                      </a-tag>
                    </template>
                  </template> -->
              </p>
            </a-card>
          </v-col>
        </v-row>
      </v-card>

      <v-card v-else flat>
        <a-empty></a-empty>
      </v-card>

      <v-speed-dial v-model="fab1" bottom right>
        <template v-slot:activator>
          <center>
            <v-pagination
              v-model="pageNumber"
              :length="paginationLength"
              :total-visible="5"
              dark
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
            ></v-pagination>
          </center>
        </template>
      </v-speed-dial>

      <!-- <div>
        <v-pagination
            v-model="pageNumber"
            :length="paginationLength"
            :total-visible="5"
            dark
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
      
          ></v-pagination>
      </div> -->
    </div>
    <v-dialog v-model="feedbackDialog" max-width="900" persistent>
      <v-card>
        <v-toolbar dense flat color="#1565c0" dark>
          <v-toolbar-title
            ><v-icon class="mr-1">mdi-help-circle-outline</v-icon
            >Feedback</v-toolbar-title
          >
          <v-spacer />

          <!-- {{$store.state.}} -->

          <v-icon @click="closeFeedbackDialog()">mdi-close</v-icon>
        </v-toolbar>

        <v-row no-gutters dense class="mt-3 pa-2">
          <v-col cols="3">
            <v-text-field
              v-model="item.feedBackNo"
              label="フィードバック番号"
              outlined
              readonly
              required
              dense
              class="mr-2"
            ></v-text-field>
          </v-col>
          <v-col cols="9">
            <v-select
              v-model="item.requestType"
              outlined
              :rules="[v => !!v || 'このフィールドは必須です']"
              dense
              label="依頼内容"
              :items="optRequestDetails"
              :readonly="readonlyRequestType==true"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-textarea 
              label="*依頼内容詳細入力欄"
              outlined
              :rules="[v => !!v || 'このフィールドは必須です']"
              dense
              v-model="item.requestDetails"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-file-input
              @change="previewFile2()"
              multiple
              v-model="attachments"
              label="添付ファイル"
              outlined
              prepend-icon="mdi-paperclip"
              dense
            >
              <!-- <template v-slot:selection="{ text }">
                <v-chip
                  small
                  label
                  color="primary"
                  close
                  @click:close="removeFile(text)"
                >
                  {{ text }}
                </v-chip>
              </template> -->
            </v-file-input>
            <v-chip
              close
              class="mb-5 mx-1" 
              v-for="(file,index) in attachmentList"
              :key="index"
              label
              color="primary"
              @click:close="removeFileAttachment(file,index)"
            >
              {{file.name}}
            </v-chip>
          </v-col>

          <v-col cols="6">
            <!-- {{$store.state.shiyoObjectFeedBack}} -->
              <!-- v-if="$store.state.shiyoObjectFeedBack.documentType == 'SHIYO'" -->
            <v-text-field
              v-if="item.requestType == '仕様マニュアルページの不具合' || item.documentType == 'SHIYO'"
              v-model="item.shiyoNumber"
              label="仕様書番号"
              outlined
              required
              dense
              class="mr-2"
              :readonly="readonlyRequestType==true"
            ></v-text-field>
            <v-text-field
              v-else-if="
                item.documentType == 'SekkeiGyoumu' ||
                  item.requestType == '設計業務マニュアルページの不具合'"
              v-model="item.docNo"
              label="設計業務マニュ番号"
              outlined
              required
              dense
              class="mr-2"
              :readonly="this.readonlyID == true"
            ></v-text-field>
            <!-- <v-text-field
              v-if="
                item.documentType == 'SekkeiKikaku' ||
                  item.requestType == '設計業務マニュアルページの不具合'
              "
              v-model="item.docNo"
              label="設計企画通信務マニュ番号"
              outlined
              required
              dense
              class="mr-2"
            ></v-text-field> -->
            <v-text-field
              v-if="item.requestType =='ルールブックページの不具合' || item.requestType =='連絡表・通達ページの不具合'"
              v-model="item.docNo"
              label="ルールブック番号"
              outlined
              required
              dense 
              class="mr-2"
              :readonly="this.readonlyID == true"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="item.consCode"
              label="お客様コード"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="images.length > 0">
            <p>Images Preview:</p>
            <template v-for="(item, i) in images">
              <v-layout row wrap :key="i" class="ma-1 pa-1">
                <span>{{ item.name }}</span>
                <img :src="item.src" width="500" />
              </v-layout>
            </template>
          </v-col>
        </v-row>
        <v-layout class="ma-1 pa-1" dense>
          <v-spacer></v-spacer>
          <v-btn color="#1565c0" dark @click="SaveFeedback()">
            <v-icon class="mr-1">mdi-send</v-icon> 送信
          </v-btn>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- ongoing 0701 -->
    <v-dialog v-model="replyDialog" max-width="600" persistent>
      <v-card>
        <v-toolbar dense flat color="#1565c0" dark>
          <v-toolbar-title
            ><v-icon class="mr-1">mdi-reply</v-icon>Reply</v-toolbar-title
          >
          <v-spacer />
          <v-icon @click="replyDialog = false">mdi-close</v-icon>
        </v-toolbar>
        <v-layout class="ma-4">
          <v-file-input
            @change="previewReplyFile()"
            multiple
            v-model="replyAttachments"
            label="添付ファイル"
            outlined
            prepend-icon="mdi-paperclip"
            dense
          >
            <template v-slot:selection="{ text }">
              <v-chip
                small
                label
                color="primary"
                close
                @click:close="removeFile(text)"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-layout>
        <v-layout class="ma-2">
          <!-- ongoing -->
          <v-textarea v-model="reply.details" label="Reply" outlined dense>
          </v-textarea>
        </v-layout>

        <!-- 2022-02-21 -->
        <!-- <v-layout style="margin-top:-20px;margin-left:10px;" >    
          <v-checkbox
            v-model="reply.replyIsImportant"
            label="Is reply important?"
          ></v-checkbox>
        </v-layout> -->

        <v-divider></v-divider>
        <v-layout class="ma-2 pa-2">
          <v-spacer />
          <v-btn dense color="primary" @click="sendReply()"
            ><v-icon> mdi-send </v-icon> Send
          </v-btn>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import AWS from "aws-sdk";
import * as binconv from "binconv";
import FileSaver from "file-saver";
import config from "../config";
import shortid from "shortid";
import Swal from "sweetalert2";
import moment from "moment";
// import md5 from 'md5'
import converter from "base64-arraybuffer";
export default {
  data() {
    return {
      loadingFeedback: true,
      isReply: false,
      item: {},
      attachments: [],
      replyAttachments: [],
      replyImages: [],
      replyDialog: false,
      feedbackDialog: false,
      filterRequestType: "ALL",
      filterReplyStatus: "ALL",
      optReplyStatus: ["ALL", "Replied", "Not Yet Replied"],
      feedBackData: [],
      reply: {},
      isEdit: false,
      images: [],
      search: "",
      fn: "",
      fbCount: 12,
      filterIsImportant: false, // 2022-02-21
      readonlyRequestType: false,
      readonlyID: false,
      pageNumber: 1, // 2022-10-28
      intRowNoAllDocs: 20,
      fab1: false,

      //NOTE: for feedback function 2024-03-11
      attachmentList: [],
    };
  },
  async created() {
    // console.log(this.userInfo)
    //ongoing 0630
    // console.log('3',this.$route.params.id)
    // console.log(this.$route.query.index)
    // this.search = 'Test A'

    this.loadAllFeedback();
    // console.log(this.filterFeedback)
    // 20210630-3
    // console.log(this.feedBackData)
  },
  watch: {
    //NOTE: for resetting scrollTop when going to other pages 2024-03-12
    pageNumber(val) {
      console.log(val)
      document.querySelector('html').scrollTop = 0
    },

    feedbackDialog(val) {
      if (val == true) {
        console.log("open dialog");
      }
    },
    search(val) {
      // 2022-11-02
      if (val) {
        this.pageNumber = 1;
      }
    },

    filterRequestType(val) {
      // 2022-11-02
      if (val) {
        this.pageNumber = 1;
      }
    },

    filterReplyStatus(val) {
      // 2022-11-02
      if (val) {
        this.pageNumber = 1;
      }
    },
  },
  methods: {
    removeFileAttachment(file,index){
      this.attachmentList.splice(index,1)
      let imgIndex = this.images.findIndex(a=>a.name==file.name)
      if(imgIndex>=0){
        this.images.splice(imgIndex,1)
      }
    },
    closeFeedbackDialog() {
      this.images = []//NOTE: reset data 2024-03-11
      this.attachmentList = []//NOTE: reset data 2024-03-11
      if (this.$route.query.id) {
        let query = Object.assign({}, this.$route.query);
        delete query.id;
        this.$router.replace({ query });
        this.item = {};
        this.feedbackDialog = false;
        this.loadAllFeedback();

        this.CHANGE_SHIYO_FEEDBACK({}); // 2022-01-17
      }
      // if (this.$route.query.id && this.$route.query.docType == "SekkeiGyoumu") {
      //   console.log("here");
      // }
      else {
        this.feedbackDialog = false;
      }
    },
    addFeedback() {
      this.item = {};
      let n = this.fn + 1;
      this.item.feedBackNo = moment().format("YYYYMMDD") + "-" + n;
      // console.log(typeof n)
      this.isEdit = false;
      this.images = [];
      this.attachments = [];
      this.feedbackDialog = true;
      this.readonlyRequestType = false
      this.readonlyID -false
      this.$store.state.shiyoObjectFeedBack.documentType = 'New Feedback'
    },
    returnImg(imgName) {
      return new Promise((resolve) => {
        AWS.config.update({
          region: "us-east-2",
          credentials: config,
        });
        var s3 = new AWS.S3({
          AccessKeyID: this.accessKeyId,
          SecretAccessKey: this.secretAccessKey,
          Region: "us-east-2",
          params: {
            bucket: "rulebook.files",
          },
          // endpoint:'https://s3.us-east-2.amazonaws.com/rulebook.files/'
        });

        var options = {
          Bucket: "rulebook.files",
          Key: `Feedback_Attachments/${imgName}`,
          // Key: "JWW_Files/02.jww",
          // Key: "9557811-2019-0905D07-Advance_Series__INDIVIDUAL_.xls",
        };
        // console.log(options, "options");
        s3.getObject(options, function(err, data) {
          if (err) {
            console.log(err, err.stack);
            // return false;
          }
          // an error occurred
          else {
            // console.log(data, "s3Data");

            // const base64 = binconv.uint8ArrayToBase64(data.Body);
            const base64 = converter.encode(data.Body);
            let content = `data:image/${imgName.substring(
              imgName.length,
              imgName.length - 3
            )};base64,`;
            // console.log(content);
            // console.log("ConvertBase64", base64);
            let finalcontent = `${content}${base64}`;
            // console.log(finalcontent);
            // var reader = new FileReader();

            // reader.readAsDataURL(base64);
            this.imagesrc = finalcontent;
            // successful response
            // console.log(err);
            resolve(this.imagesrc);
          }
        });
      });
    },
    deleteFeedback(data) {
      // 2022-02-22
      Swal.fire({
        title: `Do you want to delete <br> ${data.feedBackNo} ?`,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Confirm`,
      }).then((result) => {
        if (result.value) {
          let url = `${this.api}deleteFeedback/${data.id}`;
          axios.delete(url).then(() => {
            Swal.fire({
              icon: "success",
              title: "Successfully Deleted Feedback!",
              showConfirmButton: false,
              timer: 1500,
            });
            let idS = this.feedBackData.map((r) => {
              return r.id;
            });
            let index = idS.indexOf(data.id);
            // console.log(index)
            this.feedBackData.splice(index, 1);

            setTimeout(() => {
              this.loadAllFeedback();
            }, 500);
          });
        }
      });
    },
    EditFeedback(data) {
      this.isEdit = true;
      this.item = Object.assign({}, data);
      this.feedbackDialog = true;
    },

    // openDocument(docNo) {
    //   window.open(`/document/${docNo}`, "_blank");
    // },

    openDocument(docNo) {
        const containsLetters = /[a-zA-Z]/.test(docNo);

        if (containsLetters) {
            alert("The document could not be loaded. The link might be broken or the document is different type.");
        } else {
            window.open(`/document/${docNo}`, "_blank");
        }
    },

    // openShiyoDocument(id, product_name) {
    //   // 2022-01-17 tanskie OPEN SHIYO DOCUMENT EDITOR
    //   // window.open(
    //   //   `${this.linkURL}/shiyoDocument/${id}?productName=${product_name}`,
    //   //   "_blank"
    //   // );
    //   window.open(`/shiyoDocument/${id}?productName=${product_name}`, "_blank");
    // },

     openShiyoDocument(id, product_name) {
      if(product_name == null){
        alert("The document could not be loaded. The link might be broken");
      }
      else{
      window.open(`/shiyoDocument/${id}?productName=${product_name}`, "_blank");
        //     window.open(
        // `${this.linkURL}/shiyoDocument/${id}?productName=${product_name}`,
        // "_blank"
      }
    },


//     openShiyoDocument(id, product_name) {
//   const linkURL = `/shiyoDocument/${id}?productName=${product_name}`;
//   const linkIsWorking = "test"
//   if (linkIsWorking) {
//     window.open(linkURL, "_blank");
//   } else {
//     console.error("Link is broken or not working.");
//   }
// },
    async openSekkeiDocument(docNo) {
      // let id = "";
      // docNo = "100-9-043-1";
      // let urlForDocNO = `${this.api}sekkeiGyoumu/getSekkeiGyoumuFinalDataByDocNo/${docNo}`;
      // await axios.get(urlForDocNO).then((res) => {
      //   if (res.data) {
      //     console.log(res.data);
      //     id = res.data[0].id;

      window.open(
        `/sekkei_document/""?search=''&favorite=''&docNo=${docNo}`,
        `_blank`
      );
      //   }
      // });
    },
    sendReply() {
      this.reply.date = moment().format("YYYY-MM-DD");
      this.reply.replyAttachments = [];
      this.replyAttachments.map((r) => {
        this.reply.replyAttachments.push(r.name);
      });

      ////EMAIL PARAMS////
      let emailParams = {};
      emailParams.title = this.item.requestDetails;
      emailParams.requestType = this.item.requestType;
      emailParams.docNo = this.item.docNo;
      emailParams.recipient = this.item.feedbackByEmail;
      emailParams.feedBackNo = this.item.feedBackNo;
      emailParams.reply = this.reply.details;
      emailParams.replyDate = this.reply.date;
      console.log(this.item);

      let url = `${this.api}reply/feedback2`;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      axios.post(url, this.reply).then(() => {
        ////CALL THE UPLOAD ATTACHMENT ON AWS S3 FUNCTION////
        this.uploadReplyFileonS3();

        ////SEND EMAIL NOTIFICATION FUNCTION////
        let url2 = `${this.api}sendEmailReply_New`;
        axios.post(url2, emailParams).then((res) => {
          console.log(res.data);
        });

        this.replyDialog = false;
        Swal.fire({
          icon: "success",
          title: "Reply Sent!",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadAllFeedback();
      });
    },
    openDiscussion(data) {
      this.reply = {};
      if (data.replyDetails) {
        this.reply.details = data.replyDetails.reply;
        this.reply.replyIsImportant = data.replyDetails.replyIsImportant; // 2022-02-21
      }
      this.reply.id = data.id;
      this.replyDialog = true;
      this.item = Object.assign({}, data);
    },
    loadAllFeedback() {
      let url = `${this.api}get/feedback2`;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios.get(url).then((res) => {
        if (res.data != "No data") {
          this.fn = res.data.length;
          this.feedBackData = res.data.map((r) => {
            if (!r.feedBackNo) {
              let noFeedbackNo = res.data.filter((r) => {
                return !r.feedBackNo;
              });
              for (let i = 0; i < noFeedbackNo.length; i++) {
                let date = r.feedbackDate.replace(/-/gi, "");
                r.feedBackNo = date + "-" + [i + 1];
              }
            }
            if (this.$route.query.id) {
              this.feedbackDialog = true;
              this.item = {};
              let n = this.fn + 1;
              this.item.feedBackNo = moment().format("YYYYMMDD") + "-" + n;
              this.item.docNo = this.$route.query.id;

              this.item.shiyoNumber = this.$store.state.shiyoObjectFeedBack.shiyoNumber; // 2022-01-17
              this.item.documentType = this.$store.state.shiyoObjectFeedBack.shiyoNumber
                ? "SHIYO"
                : "RULEBOOK"; // 2022-01-17 ( MAGDADAGDAGAN TO IN THE FUTURE)
              if(this.$store.state.shiyoObjectFeedBack.documentType == 'SHIYO'){
                this.item.requestType = '仕様マニュアルページの不具合'
                this.readonlyRequestType = true
                this.readonlyID =true
                }
              // if (Object.keys(this.$store.state.shiyoObjectFeedBack).length === 0) {
              //   this.item.requestType = 'ルールブックページの不具合';
              //   this.readonlyRequestType = true
              // }
              if(this.$store.state.shiyoObjectFeedBack.documentType == 'ruleBook'){
                this.item.requestType = 'ルールブックページの不具合';
                this.readonlyRequestType = true
                this.readonlyID =true
                }

              if (this.$route.query.docType == "SekkeiGyoumu") {
                this.item.documentType = "SekkeiGyoumu";
                this.item.docNo = this.$route.query.docNo;
                this.item.requestType = '設計業務マニュアルページの不具合'
                this.readonlyRequestType = true
                this.readonlyID =true
              }

              if (this.$route.query.docType == "SekkeiKikaku") {
                this.item.documentType = "SekkeiKikaku";
                this.item.docNo = this.$route.query.docNo;
                this.item.requestType = '連絡表・通達ページの不具合'
                this.readonlyRequestType = true
                this.readonlyID =true
              }

              console.log("docType", this.$route.query.docType)
              this.item.productName = this.$store.state.shiyoObjectFeedBack
                .productName
                ? this.$store.state.shiyoObjectFeedBack.productName
                : null;
              // console.log(typeof n)
              this.isEdit = false;
              this.images = [];
              this.attachments = [];
            }
            r.isPreview = false;
            let data = [];
            if (r.attachments.length > 0) {
              r.src = [];
              r.attachments.map((r2) => {
                if (r2.match(/.jpg/g) || r2.match(/.png/g)) {
                  // console.log('filename',r2)
                  // console.log(this.returnImg(r2))
                  if (r2) {
                    data.push(this.returnImg(r2));
                  }
                }
              });
              Promise.all(data).then((source) => {
                // console.log(source)
                r.src = source;
              });
            }
            return r;
          });
          // this.fbCount = res.data.length
          // 2022-02-08
          this.feedBackData = this.feedBackData.sort((a, b) => {
            return b.feedBackNo > a.feedBackNo ? 1 : -1;
          });

          // console.log(this.feedBackData);
        }

        this.loadingFeedback = false;
      });
    },
    SaveFeedback() {
      // tanskie
      // console.log(this.item)
      if (this.item.requestType && this.item.requestDetails) {
        this.item.attachments = [];
        // this.attachments.map((r) => { //NOTE: old data for attachment of image 2024-03-11
        //   this.item.attachments.push(r.name);
        // });
        this.attachmentList.map((r) => { //NOTE: new attachment of image 2024-03-11
          this.item.attachments.push(r.name)
        })
        if (!this.item.id) {
          this.item.id = shortid.generate();
        }
        this.item.feedbackBy = this.userInfo.name;
        this.item.feedbackByEmail = this.userInfo.id;
        this.item.feedbackDate = moment().format("YYYY-MM-DD");
        this.item.replyDetails = [
          {
            response: [
              {
                details: "aaaaaa",
                date: "2021-12-12",
                from: "admin",
                replies: ["bbbbb"],
              },
            ],
          },
        ];
        if (
          this.item.requestType == "設計業務マニュアルページの不具合" &&
          this.item.documentType != "SekkeiGyoumu"
        ) {
          this.item.documentType = "SekkeiGyoumu";
        }
        //2022-10-18
        //this.item.requestType

        let url = `${this.api}add/feedback2`;
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        axios.post(url, this.item).then(() => {
          this.uploadFileonS3();
          this.feedbackDialog = false;
          if (!this.isEdit) {
            ////AFTER SAVING, THE ROUTE QUERY WILL BE REMOVED////
            if (this.$route.query.id) {
              let query = Object.assign({}, this.$route.query);
              delete query.id;
              this.$router.replace({ query });
            }
            Swal.fire({
              icon: "success",
              title: "Successfully Added!",
              showConfirmButton: false,
              timer: 1500,
            });

            // SEND EMAIL TO RULEBOOK-FEEDBACK
            let url2 = `${this.api}sendEmail_New`;
            let toSend = {};
            toSend.title = this.item.requestDetails;
            toSend.requestType = this.item.requestType;
            toSend.from = this.userInfo.id;
            toSend.name = this.userInfo.name;
            toSend.docNo = this.item.docNo;

            toSend.feedBackNo = this.item.feedBackNo;

            //2022-01-17
            // toSend.documentType = this.item.documentType == 'SHIYO' ? 'SHIYO' : 'RULEBOOK' // MADADAGDAGAN TO IN THE FUTURE //kinomment ko 20231006
            toSend.documentType = this.item.documentType;
            toSend.shiyoNumber = this.$store.state.shiyoObjectFeedBack
              .shiyoNumber
              ? this.$store.state.shiyoObjectFeedBack.shiyoNumber
              : null;
            toSend.productName = this.$store.state.shiyoObjectFeedBack
              .productName
              ? this.$store.state.shiyoObjectFeedBack.productName
              : null;

            axios.defaults.headers.common["x-api-key"] =
              "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
            axios.post(url2, toSend).then((res) => {
              console.log(res.data);
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Successfully Updated!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          this.attachmentList = [] //NOTE: reset attachmentList 2024-03-11
          this.images = [] //NOTE: reset image lish 2024-03-11
          this.loadAllFeedback();
        });
      } else {
        Swal.fire({
          icon: "info",
          title: "入力必要な項目が入力されていないようです。",
        });
      }
    },
    uploadReplyFileonS3() {
      for (let i = 0; i < this.replyAttachments.length; i++) {
        AWS.config.update({
          region: "us-east-2",
          credentials: config,
        });
        var s3 = new AWS.S3({
          AccessKeyID: this.accessKeyId,
          SecretAccessKey: this.secretAccessKey,
          Region: "us-east-2",
        });
        var uploadParams = {
          Bucket: "rulebook.files",
          Key: `Feedback_Attachments/${this.replyAttachments[i].name}`,
          Body: this.replyAttachments[i],
        };
        s3.upload(uploadParams, function(err, data) {
          if (err) {
            console.log("Error", err);
          }
          if (data) {
            // console.log("Upload Success", data.Location);
            console.log("Upload Success");
          }
        });
      }
    },
    uploadFileonS3() {
      //NOTE: change attachments to attachmentList 2024-05-08
      for (let i = 0; i < this.attachmentList.length; i++) {
        AWS.config.update({
          region: "us-east-2",
          credentials: config,
        });
        var s3 = new AWS.S3({
          AccessKeyID: this.accessKeyId,
          SecretAccessKey: this.secretAccessKey,
          Region: "us-east-2",
        });
        var uploadParams = {
          Bucket: "rulebook.files",
          Key: `Feedback_Attachments/${this.attachmentList[i].name}`,
          Body: this.attachmentList[i],
        };
        s3.upload(uploadParams, function(err, data) {
          if (err) {
            console.log("Error", err);
          }
          if (data) {
            // console.log("Upload Success", data.Location);
            console.log("Upload Success");
          }
        });
      }
    },
    FileSrc(filename) {
      AWS.config.update({
        region: "us-east-2",
        credentials: config,
      });
      var s3 = new AWS.S3({
        AccessKeyID: this.accessKeyId,
        SecretAccessKey: this.secretAccessKey,
        Region: "us-east-2",
      });

      var options = {
        Bucket: "rulebook.files",
        Key: `Feedback_Attachments/${filename}`,
      };
      s3.getObject(options, function(err, data) {
        if (err) console.log(err, err.stack);
        else {
          var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
            type: `${data.ContentType};charset=utf-8`,
          });
          FileSaver.saveAs(blob, `${filename}`);
        } // successful response
      });
    },
    previewReplyFile() {
      this.replyImages = [];
      for (let i = 0; i < this.replyAttachments.length; i++) {
        if (
          this.replyAttachments[i].name.match(/.png/g) != null ||
          this.replyAttachments[i].name.match(/.jpg/g) != null ||
          this.replyAttachments[i].name.match(/.JPG/g) != null ||
          this.replyAttachments[i].name.match(/.PNG/g) != null
        ) {
          let previewFile = {};
          previewFile.src = URL.createObjectURL(this.replyAttachments[i]);
          previewFile.name = this.replyAttachments[i].name;

          this.replyImages.push(previewFile);
          // window.open(previewFile);
        }
      }
    },
    previewFile2() {
      // this.images = []; //NOTE: disable resetting of images upon inputting image file 2024-03-11
      for (let i = 0; i < this.attachments.length; i++) {
        if (
          this.attachments[i].name.match(/.png/g) != null ||
          this.attachments[i].name.match(/.jpg/g) != null ||
          this.attachments[i].name.match(/.JPG/g) != null ||
          this.attachments[i].name.match(/.PNG/g) != null
        ) {
          let previewFile = {};
          previewFile.src = URL.createObjectURL(this.attachments[i]);
          previewFile.name = this.attachments[i].name;
          if(!this.images.some(a=>a.name===this.attachments[i].name)){ //NOTE: insert data if not existing in attachmentList 2024-03-11
            this.images.push(previewFile);
          }
          // window.open(previewFile);
        }
        if(!this.attachmentList.some(a=>a.name===this.attachments[i].name)){ //NOTE: insert data if not existing in attachmentList 2024-03-11
          this.attachmentList.push(this.attachments[i]);
        }
      }
    },
    removeReplyFile(data) {
      let index = -1;
      this.replyAttachments.map((r, i) => {
        if (r.name == data) {
          index = i;
        }
      });
      this.replyAttachments.splice(index, 1);
      this.previewReplyFile();
    },
    removeFile(data) {
      let index = -1;
      this.attachments.map((r, i) => {
        if (r.name == data) {
          index = i;
        }
      });
      this.attachments.splice(index, 1);
      this.previewFile2();
    },
  },
  computed: {
    // 2022-10-28
    paginationLength() {
      let l = this.filterFeedback.length,
        s = this.intRowNoAllDocs;
      return Math.ceil(l / s);
    },
    paginatedFeedback() {
      const start = (this.pageNumber - 1) * this.intRowNoAllDocs,
        end = start + this.intRowNoAllDocs;
      return this.filterFeedback.slice(start, end);
    },

    filterFeedback() {
      let filterData = [];
      if (this.$route.params.id) {
        filterData = this.feedBackData.filter((r) => {
          return r.docNo == this.$route.params.id;
          // return JSON.stringify(Object.values(r)).toUpperCase().match(this.$route.params.id);
        });
      } else if (this.$route.query.feedBackNo) {
        filterData = this.feedBackData.filter((r) => {
          let search = new RegExp(
            this.$route.query.feedBackNo.toUpperCase(),
            "g"
          );
          return JSON.stringify(Object.values(r))
            .toUpperCase()
            .match(search);
        });
        // return this.search = this.$route.query.feedBackNo
      } else {
        filterData = this.feedBackData
          .filter((r) => {
            let search = new RegExp(this.search.toUpperCase(), "g");
            return JSON.stringify(Object.values(r))
              .toUpperCase()
              .match(search);
          })
          .filter((r) => {
            if (this.filterRequestType != "ALL") {
              return r.requestType == this.filterRequestType;
            } else return r;
          })
          .filter((r) => {
            if (this.filterReplyStatus == "Replied") {
              // return r.replyDetails;
              if (typeof r.replyDetails == "object") {
                // 2022-01-17 modified
                return r.replyDetails.reply != undefined;
              }
            } else if (this.filterReplyStatus == "Not Yet Replied") {
              // return !r.replyDetails

              if (r.replyDetails.length > 0) {
                //  // 2022-01-17 modified
                return r.replyDetails[0].response[0].details == "aaaaaa";
              }
            } else return r;
          })
          .filter((r) => {
            // 2022-02-21

            if (this.filterIsImportant == true) {
              if (typeof r.replyDetails == "object") {
                return r.replyDetails.replyIsImportant == true;
              }
            } else {
              return r;
            }
          });
      }

      return filterData;
    },
    optRequestDetails() {
      if (!this.feedbackDialog) {
        return [
          "ALL",
          // "ルールブックシステム機能改善提案",
          "ルールブックページの不具合",
          // "ルールに関する改善提案",
          "仕様マニュアルページの不具合", // 2022-10-18
          "連絡表・通達ページの不具合", // 2023-04-11
          "設計業務マニュアルページの不具合", //added 20230720
          "その他",
        ];
      } else {
        return [
          // "ルールブックシステム機能改善提案",
          "ルールブックページの不具合",
          // "ルールに関する改善提案",
          "仕様マニュアルページの不具合", // 2022-10-18
          "連絡表・通達ページの不具合", // 2023-04-11
          "設計業務マニュアルページの不具合", //added 20230720
          "その他",
        ];
      }
    },
    // documentTypeComputed(){
    //   if(this.item.requestType == "設計業務マニュアルページの不具合"){

    //   }
    // }
  },
};
</script>
<style scoped>
#box-border {
  box-sizing: border-box;
  padding: 4px;
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid #d9d9d9;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}
#cardDetails {
  font-size: 14px;
  font-weight: bold;
}
img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  -webkit-transform: scale(0.999);
  transform: scale(0.999);
  image-rendering: -webkit-optimize-contrast;
}

/* 2022-11-02 */
#main-edit .v-speed-dial {
  position: fixed !important;
}

#main-edit .v-btn--floating {
  position: absolute !important;
}

.v-speed-dial {
  display: block;
  padding: 2px;
  border: 1px solid grey;
  margin-left: 20px;
  /* width: calc(100% - 280px); */
  /* background-color: #213c57; */
  width: 98%;
  background-color: #ededed;
  text-align: right;
}
</style>
